<template>
  <div>
    <customer-schedule class="mb-3" :deleted-status="deletedStatus" />
    <template v-if="customerSubscriber">
      <customer-prospect-subscription v-if="customerProspect" :customer-name="customer.name"
        :customer-email="customer.email" :subscription="customerProspectSubscription"
        @activated="$emit('subscription-activated')" />
      <customer-subscription v-else class="mb-2" :customer-name="customer.name"
        :customer-subscription="customerSubscription" :customer-email="customer.email"
        :customer-employer="customer.user_corporation_id" :deleted-status="deletedStatus" :customer-id="customer.id" />
    </template>
    <customer-pauses v-if="!customer.one_time_customer" :customer="customer" />
    <customer-food-selections v-if="validMealPlan" />
    <customer-gardener-log v-if="!customer.one_time_customer" :customer="customer" />
  </div>
</template>

<script>
import CustomerSchedule from "./Schedule/CustomerSchedule";
import CustomerSubscription from "./Subscription/CustomerSubscription";
import CustomerPauses from "./Pauses/CustomerPauses";
import CustomerFoodSelections from "@/components/Customers/Individual/Overview/FoodSelections/CustomerFoodSelections";
import CustomerGardenerLog from "@/components/Customers/Individual/Gardener/Logs/CustomerGardenerLog";
import CustomerProspectSubscription from "@/components/Customers/Individual/Overview/Subscription/CustomerProspectSubscription";

export default {
  name: "CustomerOverview",
  props: {
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
    deletedStatus: {
      type: Boolean,
      return: false,
    },
  },
  components: {
    CustomerProspectSubscription,
    CustomerSchedule,
    CustomerSubscription,
    CustomerPauses,
    CustomerFoodSelections,
    CustomerGardenerLog,
  },
  computed: {
    menuTypeVariation() {
      if (this.customer.menu_type_variation_id === null) {
        return {};
      }
      let menus = this.$store.getters.menu_variations;
      if (menus?.length) {
        let userVariation = menus.filter((menu) => {
          if (this.customer.menu_type_variation_id === menu.id) {
            return menu;
          }
        });
        if (userVariation?.length) {
          return {
            employee: "fixed",
            menu: {
              name: userVariation[0].name,
              id: this.customer.menu_type_variation_id,
            },
          };
        }
      }
      return {};
    },
    customerSubscriber() {
      return !this.customer.one_time_customer;
    },
    customerProspect() {
      return (
        this.customer.customer_status === "prospect" &&
        this.customer.prospect_plan.length
      );
    },
    customerProspectSubscription() {
      const { prospect_plan } = this.customer;
      let subscription = prospect_plan?.length ? prospect_plan[0] : {};
      if (subscription) {
        const plan =
          typeof subscription.plan === "string"
            ? JSON.parse(subscription?.plan)
            : subscription.plan;
        return {
          customerId: this.customer.id,
          name: this.customer.name,
          email: this.customer.email,
          services: Object.keys(plan),
          plan,
          amount: Object.keys(plan).reduce((total, service) => {
            return total + plan[service].amount;
          }, 0),
          start_date: subscription.start_date,
        };
      }
      return {};
    },
    customerSubscription() {
      const subscription =
        this.customer.next_billing_date?.length &&
        this.customer?.next_billing_date[0];
      return {
        customerId: this.customer?.id,
        name: this.customer.name,
        email: this.customer?.email,
        id: this.customer?.subscription_id,
        plan: subscription ? (subscription.plan ? subscription?.plan : {}) : {},
        amount: subscription && subscription.amount,
        next_charge_date: subscription && subscription?.next_charge_date,
        menu_type: this.menuTypeVariation,
      };
    },
    validMealPlan() {
      const nextBillingDate = this.customer?.next_billing_date?.[0];
      const plan = nextBillingDate ? nextBillingDate.plan : undefined;
      return !!(plan && plan?.meal);
    },
  },
};
</script>

<style lang="scss">
.empty-indicator {
  margin: 10px 0 20px;
  font-weight: 400;
  text-align: center;
}
</style>
